import axios from 'axios'

// const IS_TEST = false
// const BASE_URL = 'https://ggtools.thinkerx.com/'
// const TEST_BASE_URL = 'https://beta.eggi.cn/'
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    token: sessionStorage.getItem('token'),
  },
})

export default http
