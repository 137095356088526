import Vue from 'vue'
import VueRouter from 'vue-router'

import { verifyToken } from '@/apis/login'
import httpRequest from '@/apis/request'

import { getUrlParams } from '@yige/utils'

import store from '../store'

const SeeyardOrder = () => import('@/views/SeeyardOrder/index.vue')
const SeeyardClient = () => import('@/views/SeeyardClient/index.vue')
const OrderMeasure = () => import('@/views/orderMeasure/orderMeasure.vue')
const Training = () => import('@/views/training/training.vue')
const OrderManage = () => import('@/views/orderManage/orderManage.vue')
const OnlineOrder = () =>
  import('@/views/orderManage/onlineOrder/onlineOrder.vue')
const ExperienceOrder = () =>
  import('@/views/orderManage/experienceOrder/experienceOrder.vue')
const Login = () => import('@/views/login.vue')
const CourtyardOrder = () => import('@/views/courtyardOrder/courtyardOrder.vue')
const InformationReview = () =>
  import('@/views/cloud-factory/information-review/information-review.vue')
const FactoryInfo = () =>
  import('@/views/cloud-factory/factory-info/factory-info.vue')
const Customerjoining = () =>
  import('@/views/cloud-factory/customer-joining/customer-joining.vue')
const CloudFactory = () => import('@/views/cloud-factory/cloud-factory.vue')
const CloudFactoryInfo = () =>
  import('@/views/cloud-factory/cloud-factory-info/cloud-factory-info.vue')
const AddFactory = () =>
  import('@/views/cloud-factory/add-factory/add-factory.vue')
const ApplyJoin = () => import('@/views/applyJoin/applyJoin.vue')
const ApplyChain = () => import('@/views/applyChain/applyChain.vue')
const ApplyAfterSales = () =>
  import('@/views/applyAfterSales/applyAfterSales.vue')
const LiveAnnouncement = () =>
  import('@/views/LiveAnnouncement/LiveAnnouncement.vue')
const ApplytraininginStitution = () =>
  import('@/views/ApplytraininginStitution/ApplytraininginStitution.vue')
const ServiceStation = () => import('@/views/serviceStation/index.vue')
const DataStatistics = () => import('@/views/dataStatistics/index.vue')
const PromotionPersonnelManagement = () =>
  import('@/views/promotionPersonnelManagement/index.vue')
const salemanManagement = () => import('@/views/salemanManagement/index.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: SeeyardOrder,
    meta: {
      needLogin: true,
      hash: 'seeyardOrder',
    },
    redirect: '/seeyardOrder',
  },
  {
    path: '/orderMeasure',
    name: 'OrderMeasure',
    component: OrderMeasure,
    meta: {
      needLogin: true,
      hash: 'orderMeasure',
    },
  },
  {
    path: '/applyJoin',
    name: 'ApplyJoin',
    component: ApplyJoin,
    meta: {
      needLogin: true,
      hash: 'applyJoin',
    },
  },
  {
    path: '/applyAfterSales',
    name: 'ApplyAfterSales',
    component: ApplyAfterSales,
    meta: {
      needLogin: true,
      hash: 'applyAfterSales',
    },
  },
  {
    path: '/courtyardOrder',
    name: 'CourtyardOrder',
    component: CourtyardOrder,
    meta: {
      needLogin: true,
      hash: 'courtyardOrder',
    },
  },
  {
    path: '/orderManage',
    name: 'OrderManage',
    component: OrderManage,
    meta: {
      needLogin: true,
      hash: 'orderManage',
    },
    redirect: '/orderManage/experienceOrder',
    children: [
      {
        path: 'onlineOrder',
        name: 'OnlineOrder',
        component: OnlineOrder,
        meta: {
          needLogin: true,
          hash: 'orderManage',
        },
      },
      {
        path: 'experienceOrder',
        name: 'ExperienceOrder',
        component: ExperienceOrder,
        meta: {
          needLogin: true,
          hash: 'orderManage',
        },
      },
    ],
  },
  {
    path: '/cloud-factory',
    name: 'CloudFactory',
    component: CloudFactory,
    meta: {
      needLogin: true,
      hash: 'cloudFactory',
    },
    redirect: '/cloud-factory/cloud-factory-info',
    children: [
      {
        path: 'cloud-factory-info',
        name: 'CloudFactoryInfo',
        component: CloudFactoryInfo,
        meta: {
          needLogin: true,
          hash: 'cloudFactory',
        },
      },
      {
        path: 'add-factory',
        name: 'AddFactory',
        component: AddFactory,
        meta: {
          needLogin: true,
          hash: 'addfactory',
        },
      },
      {
        path: 'customer-joining',
        name: 'Customerjoining',
        component: Customerjoining,
        meta: {
          needLogin: true,
          hash: 'cloudFactory',
        },
      },
      {
        path: 'information-review',
        name: 'InformationReview',
        component: InformationReview,
        meta: {
          needLogin: true,
          hash: 'cloudFactory',
        },
      },
      {
        path: 'factory-info',
        name: 'FactoryInfo',
        component: FactoryInfo,
        meta: {
          needLogin: true,
          hash: 'FactoryInfo',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      needLogin: true,
      noHead: true,
    },
  },
  {
    path: '/training',
    name: 'Training',
    component: Training,
    meta: {
      needLogin: true,
      hash: 'training',
    },
  },
  {
    path: '/ApplytraininginStitution',
    name: 'ApplytraininginStitution',
    component: ApplytraininginStitution,
    meta: {
      needLogin: true,
      hash: 'ApplytraininginStitution',
    },
  },
  {
    path: '/ApplyChain',
    name: 'ApplyChain',
    component: ApplyChain,
    meta: {
      needLogin: true,
      hash: 'ApplyChain',
    },
  },
  {
    path: '/LiveAnnouncement',
    name: 'LiveAnnouncement',
    component: LiveAnnouncement,
    meta: {
      needLogin: true,
      hash: 'LiveAnnouncement',
    },
  },
  {
    path: '/seeyardOrder',
    name: 'SeeyardOrder',
    component: SeeyardOrder,
    meta: {
      needLogin: true,
      hash: 'seeyardOrder',
    },
  },
  {
    path: '/serviceStation',
    name: 'serviceStation',
    component: ServiceStation,
    meta: {
      needLogin: true,
      hash: 'serviceStation',
    },
  },
  {
    path: '/seeyardClient',
    name: 'SeeyardClient',
    component: SeeyardClient,
    meta: {
      needLogin: true,
      hash: 'seeyardClient',
    },
  },
  {
    path: '/dataStatistics',
    name: 'dataStatistics',
    component: DataStatistics,
    meta: {
      needLogin: true,
      hash: 'dataStatistics',
    },
  },
  {
    path: '/promotionPersonnelManagement',
    name: 'promotionPersonnelManagement',
    component: PromotionPersonnelManagement,
    meta: {
      needLogin: true,
      hash: 'promotionPersonnelManagement',
    },
  },
  {
    path: '/salemanManagement',
    name: 'salemanManagement',
    component: salemanManagement,
    meta: {
      needLogin: true,
      hash: 'salemanManagement',
    },
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
// 路由全局守卫，进入其它页面未登录时跳转到登录页
router.beforeEach((to, from, next) => {
  if (to.path !== '/login') {
    const isLogin = sessionStorage.getItem('_isLogin')
    if (isLogin) {
      next()
    } else {
      let urlObj = getUrlParams()
      if (urlObj.token) {
        verifyToken({ token: urlObj.token }, (res) => {
          if (res.status == 1) {
            let data = res.data
            httpRequest.setOptions({
              token: data.token,
              refreshToken: data.refresh_token,
            })
            window.sessionStorage.setItem('_isLogin', true)
            window.localStorage.setItem('phone', data.phone)
            sessionStorage.setItem('token', data.token)
            window.localStorage.setItem('token', data.token)
            window.sessionStorage.setItem('isMainAccount', data.mainaccount)
            window.localStorage.setItem('isMainAccount', data.mainaccount)
            next()
          } else {
            next({
              path: '/login',
            })
            return
          }
        })
      } else {
        next({
          path: '/login',
        })
        return
      }
    }
  } else {
    next()
    return
  }
})
export default router
