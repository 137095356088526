const interceptorsRequest = function (http, router) {
  http.interceptors.response.use(
    async function (response) {
      return response
    },
    async function (error) {
      if (error.response.status == 401) {
        router.push('/login')
      }
    }
  )
  http.interceptors.request.use(
    function (config) {
      const token = window.localStorage.getItem('token')
      // 在发送请求之前做些什么
      if (token) {
        config.headers = {
          ...config.headers,
          token: window.localStorage.getItem('token'),
        }
      }
      return config
    },
    function (error) {
      // 对请求错误做些什么
      return Promise.reject(error)
    }
  )
}
export default interceptorsRequest
