import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let store = new Vuex.Store({
  state: {
    userInfo: JSON.parse(sessionStorage.getItem('userInfo')) || {},
    order_nums: {
      experience_order_count: 0,
      online_order_count: 12,
    },
    tatal_order_num: {
      online: {
        no_visit: 0,
        is_visit: 0,
        total: 0,
      },
      experience: {
        no_visit: 0,
        is_visit: 0,
        total: 0,
      },
    },
    customer_count: {
      no_visit: 0,
      is_visit: 0,
      totla: 0,
    },
    info_check: 0,
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    setOrder_nums(state, value) {
      state.order_nums = value
    },
    setTatalOrderNum(state, value) {
      state.tatal_order_num = value
      console.log(state.tatal_order_num)
    },
    setCustomerNum(state, value) {
      state.customer_count = value
    },
    setInfoCheck(state, value) {
      state.info_check = value
    },
  },
  actions: {
    getInfocheck(context) {
      Vue.prototype.$request.getInfoCheck({ examine_status: 0 }, (res) => {
        if (res.status == 1) {
          let data = res.data
          let count = data.count
          context.commit('setInfoCheck', count)
        }
      })
    },
    getCustomerCount(context) {
      Vue.prototype.$request.getCustomerCount({}, (res) => {
        if (res.status == 1) {
          let data = res.data
          let no_visit = 0
          let is_visit = 0
          data.forEach((i) => {
            if (i.status == 1) {
              is_visit = i.count
            } else {
              no_visit = i.count
            }
          })
          let counts = {
            is_visit,
            no_visit,
            total: is_visit + no_visit,
          }
          context.commit('setCustomerNum', counts)
        }
      })
    },
    getOrderNum(context) {
      Vue.prototype.$request.checkRevisitNum({}, (res) => {
        console.log('count', res)
        if (res.status == 1) {
          let data = res.data
          console.log(data)
          let experience_order_count =
            res.data.processed_count + res.data.unprocessed_count
          let online_order_count = 0
          let online = {
            no_visit: 0,
            is_visit: 0,
            total: 0,
          }
          let experience = {
            no_visit: res.data.unprocessed_count,
            is_visit: res.data.processed_count,
            total: res.data.processed_count + res.data.unprocessed_count,
          }
          // data.forEach((item) => {
          //   // 左边未处理订单数量和页面上订单状态数量
          //   if (!item.is_revisit) {
          //     if (item.type == 1) {
          //       online_order_count = item.count
          //       online.no_visit = item.count
          //       online.total += item.count
          //     } else {
          //       experience_order_count = item.count
          //       experience.no_visit = item.count
          //       experience.total += item.count
          //     }
          //   } else {
          //     if (item.type == 1) {
          //       online.is_visit = item.count
          //       online.total += item.count
          //     } else {
          //       experience.is_visit = item.count
          //       experience.total += item.count
          //     }
          //   }
          // })
          context.commit('setOrder_nums', {
            experience_order_count,
            online_order_count,
          })
          context.commit('setTatalOrderNum', {
            online,
            experience,
          })
        }
      })
    },
  },
  modules: {},
})
export default store
