import http from './http'
import interceptorsRequest from './interceptorsRequest'

export default function request(vueConfig, router) {
  interceptorsRequest(http, router)
  vueConfig.$get = async function (url, params, callBack) {
    await http
      .get(url, {
        params: params,
      })
      .then((res) => {
        callBack(res)
      })
  }
  vueConfig.$post = async function (url, params, callBack) {
    await http.post(url, params).then((res) => {
      callBack(res)
    })
  }
  vueConfig.$request = generateRequestWithHost
}
const generateRequestWithHost = {
  // 接口错误导致刷新报错
  // getUserInfo: (payload, callback) => {
  //   http
  //     .get('/erp/get_user_info', {
  //       params: payload,
  //     })
  //     .then((res) => {
  //       callback(res.data)
  //     })
  // },
  sendCode: (payload, callback) => {
    http.post('/seeyard/send_login_code', payload).then((res) => {
      callback(res.data)
    })
  },
  loginAdmin: (payload, callback) => {
    http.post('/seeyard/login_admin', payload).then((res) => {
      callback(res.data)
    })
  },
  getSoldList: (payload, callback) => {
    http
      .get('/seeyard/get_sold_service_list', { params: payload })
      .then((res) => {
        callback(res.data)
      })
  },
  getShyUserList: (payload, callback) => {
    http.get('/seeyard/get_measure_list', { params: payload }).then((res) => {
      callback(res.data)
    })
  },
  seeyardAdminOrder: (payload, callback) => {
    http.get('/seeyard/admin/order', { params: payload }).then((res) => {
      callback(res.data)
    })
  },
  checkRevisitNum: (payload, callback) => {
    http.get('/seeyard/check_revisit_num', { params: payload }).then((res) => {
      callback(res.data)
    })
  },
  getOrderRevisitList: (payload, callback) => {
    http
      .get('/seeyard/get_order_revisit_list', { params: payload })
      .then((res) => {
        callback(res.data)
      })
  },
  getTrialRevisitList: (payload, callback) => {
    http
      .get('/seeyard/manage/get_customer_list', { params: payload })
      .then((res) => {
        callback(res.data)
      })
  },
  toVisit: (payload, callback) => {
    http.post('/seeyard/customer_return_visit', payload).then((res) => {
      callback(res.data)
    })
  },
  getRevisitDetail: (payload, callback) => {
    http.get('/seeyard/get_revisit_detail', { params: payload }).then((res) => {
      callback(res.data)
    })
  },
  // 获取机构列表
  getAgencyList: (payload, callback) => {
    http.get(`/seeyard/get_agency_list_back?${payload}`).then((res) => {
      callback(res.data)
    })
  },
  // 特殊号码获取配信机构列表
  getAgencyListSpec: (payload, callback) => {
    http.get(`/seeyard/get_agency_list_special?${payload}`).then((res) => {
      callback(res.data)
    })
  },
  // 获取培训机构详情
  getAgencyInfo: (payload, callback) => {
    http.get(`/seeyard/get_agency_info?id=${payload}`).then((res) => {
      callback(res.data)
    })
  },
  // 删除培训机构
  delAgencyInfo: (payload, callback) => {
    http.post('/seeyard/delete_agency', payload).then((res) => {
      callback(res.data)
    })
  },
  // 删除培训机构下达人
  delService: (payload, callback) => {
    http.post('/seeyard/delete_service', payload).then((res) => {
      callback(res.data)
    })
  },
  // 获取抖音授权用户信息
  getDyUserInfo: (payload, callback) => {
    http.get('/seeyard/get_dy_user_info', { params: payload }).then((res) => {
      callback(res.data)
    })
  },
  // 添加机构
  addSeeyardAgency: (payload, callback) => {
    http.post('/seeyard/add_seeyard_agency', payload).then((res) => {
      callback(res.data)
    })
  },
  // 添加达人
  seeyardBindDyAccount: (payload, callback) => {
    http.post('/seeyard/seeyard_bind_dy_account', payload).then((res) => {
      callback(res.data)
    })
  },
  // 获取培训机构列表
  getApplytraininginStitution: (payload, callback) => {
    http.get('/seeyard/get_training_list', { params: payload }).then((res) => {
      callback(res.data)
    })
  },
  // 获取培训机构列表
  getGroupInfo: (payload, callback) => {
    http.get('/seeyard/get_group_info', { params: payload }).then((res) => {
      callback(res.data)
    })
  },
  // 获取云工厂列表
  getFactroyList: (payload, callback) => {
    http.post('/seeyard/get_cloud_factory_list ', payload).then((res) => {
      callback(res.data)
    })
  },
  // 培训机构添加培训信息
  getAddInfo: (payload, callback) => {
    http.post('/seeyard/set_training_info ', payload).then((res) => {
      callback(res.data)
    })
  },
  // 获取云工厂信息列表
  getCloudFactoryList: (payload, callback) => {
    http.post('/seeyard/get_cloud_factory_list', payload).then((res) => {
      callback(res.data)
    })
  },
  //删除云工厂
  deletefactoryasync: (payload, callback) => {
    http.post('/seeyard/delete_cloud_factory', payload).then((res) => {
      callback(res.data)
    })
  },
  //下架云工厂
  onOrOffTheShelfCloudFactory: (payload, callback) => {
    http.post('/seeyard/manage_cloud_factory', payload).then((res) => {
      callback(res.data)
    })
  },
  //审核通过
  setExamineStatus: async (payload, callback) => {
    http.post('/seeyard/set_examine_status', payload).then((res) => {
      callback(res.data)
    })
  },
  // 获取创建者
  getCreateName: (payload, callback) => {
    http.post('/seeyard/get_user_name', payload).then((res) => {
      callback(res.data)
    })
  },
  // 批量下架
  getManageCloudFact: (payload, callback) => {
    http.post('/seeyard/manage_cloud_factory', payload).then((res) => {
      callback(res.data)
    })
  },
  // 批量删除
  getAllDel: (payload, callback) => {
    http.post('/seeyard/delete_cloud_factory', payload).then((res) => {
      callback(res.data)
    })
  },
  // 新增云工厂
  getInsertCloudFact: (payload, callback) => {
    http.post('/seeyard/insert_cloud_factory', payload).then((res) => {
      callback(res.data)
    })
  },
  // 回显-编辑-云工厂
  getReviewList: (payload, callback) => {
    http.post('/seeyard/get_cloud_factory_info', payload).then((res) => {
      callback(res.data)
    })
  },
  // 客户加盟列表
  getRevisitList: (payload, callback) => {
    http.post('/seeyard/get_revisit_list', payload).then((res) => {
      callback(res.data)
    })
  },
  // 获取客户加盟数量
  getCustomerCount: (payload, callback) => {
    http.post('/seeyard/check_revisit', payload).then((res) => {
      callback(res.data)
    })
  },
  // 回访
  updataRevisit: (payload, callback) => {
    http.post('/seeyard/update_revisit', payload).then((res) => {
      callback(res.data)
    })
  },
  // 获取信息审核数量
  getInfoCheck: (payload, callback) => {
    http
      .get('/seeyard/get_examine_status_count', { params: payload })
      .then((res) => {
        callback(res.data)
      })
  },
  // 获取供应链审核列表
  getExamineList: (payload, callback) => {
    http
      .get('/seeyard/get_examine_service_list', { params: payload })
      .then((res) => {
        callback(res.data)
      })
  },
  // 供应链审核
  getUpdateExamineStatus: (payload, callback) => {
    http
      .post('/seeyard/update_service_examine_status ', payload)
      .then((res) => {
        callback(res.data)
      })
  },
  // 获取相应供应链数量
  getListNum: (payload, callback) => {
    http
      .get('/seeyard/get_service_examine_num', { params: payload })
      .then((res) => {
        callback(res.data)
      })
  },
  // 搜索云工厂
  getSearchCloudFact: (payload, callback) => {
    http.post('/seeyard/manage/get_factory_list', payload).then((res) => {
      callback(res.data)
    })
  },
  //搜索达人
  getEmployees: (payload, callBack) => {
    http.get('seeyard/manage/employees', { params: payload }).then((res) => {
      callBack(res.data)
    })
  },
  // 添加授权区域 新增信息
  getAddOnfo: (payload, callback) => {
    http.post('/seeyard/set_factory', payload).then((res) => {
      callback(res.data)
    })
  },
  // 获取培训公告列表
  getTranningPortList: (payload, callback) => {
    http
      .get('/seeyard/manage/get_announcement_list', { params: payload })
      .then((res) => {
        callback(res.data)
      })
  },
  // 删除培训公告
  delTranningPortList: (payload, callback) => {
    http
      .get('/seeyard/manage/delete_announcement', { params: payload })
      .then((res) => {
        callback(res.data)
      })
  },
  // 操作培训公告(上线下线)
  doTranningPortList: (payload, callback) => {
    http
      .post('/seeyard/manage/update_announcement_online_status', payload)
      .then((res) => {
        callback(res.data)
      })
  },
  // 添加培训公告
  addTranningPortList: (payload, callback) => {
    http
      .post('/seeyard/manage/add_training_announcement', payload)
      .then((res) => {
        callback(res.data)
      })
  },
}
