import HttpRequest from '@yige/request'

export const IS_TEST = process.env.VUE_APP_ENV !== 'production'

//柜柜PYTHON
export const host = IS_TEST
  ? 'https://test-api.eggrj.com'
  : 'https://ggtools.thinkerx.com'

const httpRequest = new HttpRequest({
  cacheTokenMethods: 'sessionStorage',
  baseUrl: host,
  token: sessionStorage.getItem('token') as string,
})
export default httpRequest
