<template>
  <div style="height: 64px">
    <div
      class="top-nav flex flex-main--justify flex-cross--center w100 color-f"
    >
      <span>四合院全屋定制后台管理系统</span>
      <div
        class="user flex flex-main--justify flex-cross--center h100 fs14 relative cursor-pointer"
      >
        <div class="avatar">
          <img
            class="avatar-img w100 h100"
            src="../assets/topNavHeader.png"
            alt=""
          />
        </div>

        <span class="phone cursor-pointer mr8 ml18">{{
          userInfo && userInfo.phone ? userInfo.phone : ''
        }}</span>
        <i class="iconfont icon-arrow-down"></i>
        <div
          class="handle absolute r0 color-o-9 pt8 pb8 pl16 pr16 fs14"
          @click="quitLogin"
        >
          <span class="exit cursor-pointer">退出登录</span>
        </div>
      </div>
    </div>
    <a-modal
      v-model="isShowExitModal"
      title="退出登录"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消"
    >
      <p>请确认是否退出登录？</p>
    </a-modal>
  </div>
</template>

<script lang="js">
import { mapState } from 'vuex';


export default {
  name: 'BackHead',
  data() {
    return {
      isShowExitModal: false,
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    quitLogin() {
      this.isShowExitModal = true
    },
    handleOk() {
      localStorage.clear()
      sessionStorage.clear()
      this.$router.push('/login')
    },
    handleCancel() {
      this.isShowExitModal = false
    },
  },
}
</script>

<style lang="less" scoped>
.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 64px;
  background: #2c2c2c;
  padding: 0 32px;
  box-sizing: border-box;
  .user {
    .avatar {
      width: 40px;
      height: 40px;
    }
    .phone {
      line-height: 64px;
    }
    .iconfont {
      display: inline-block;
      transform: rotate(-90deg);
      transition: all 0.5s;
    }
    .handle {
      top: 64px;
      box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08),
        0px 16px 24px 2px rgba(0, 0, 0, 0.04),
        0px 6px 30px 5px rgba(0, 0, 0, 0.05);
      display: none;
      width: 136px;
      height: 56px;
      box-sizing: border-box;
      line-height: 40px;
      .exit:hover {
        background: #fafafa;
      }
    }
    &:hover .icon {
      transform: rotate(0deg);
    }
    &:hover .handle {
      display: block;
    }
  }
}
</style>
