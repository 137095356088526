<template>
  <div style="height: 64px" class="mb16">
    <div class="main_nav">
      <span
        :class="[activeTableNav === item.type ? 'activeNav' : '']"
        v-for="(item, index) in navList"
        :key="index"
        @click="changeNav(item)"
        >{{ item.title }}</span
      >
    </div>
    <!-- <div style="position: fixed; top: 128px">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="Tab 1"> Content of Tab Pane 1 </a-tab-pane>
        <a-tab-pane key="2" tab="Tab 2" force-render>
          Content of Tab Pane 2
        </a-tab-pane>
        <a-tab-pane key="3" tab="Tab 3"> Content of Tab Pane 3 </a-tab-pane>
      </a-tabs>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'MainNav',
  data() {
    return {
      activeTableNav: 'seeyardOrder',
      isMainAccount: 'false',
      navList: [
        {
          title: '四合院订单',
          key: 9,
          type: 'seeyardOrder',
          path: '/seeyardOrder',
        },
      ],
    }
  },
  computed: {
    getNavList: () => {
      let list = []
      if (window.localStorage.getItem('phone') == '13182776666') {
        list = [
          { title: '培训机构', key: 1, type: 'training', path: '/training' },
        ]
        return list
      } else {
        list = this.navList
        return list
      }
    },
  },
  mounted() {
    this.isMainAccount = localStorage.getItem('isMainAccount')
    this.navList =
      this.isMainAccount === '1'
        ? [
            {
              title: '四合院订单',
              key: 1,
              type: 'seeyardOrder',
              path: '/seeyardOrder',
            },
            {
              title: '服务站',
              key: 2,
              type: 'serviceStation',
              path: '/serviceStation',
            },
            {
              title: '四合院客户',
              key: 3,
              type: 'seeyardClient',
              path: '/seeyardClient',
            },
            {
              title: '数据统计',
              key: 4,
              type: 'dataStatistics',
              path: '/dataStatistics',
            },
            {
              title: '推广人员管理',
              key: 5,
              type: 'promotionPersonnelManagement',
              path: '/promotionPersonnelManagement',
            },
            {
              title: '销售管理',
              key: 6,
              type: 'salemanManagement',
              path: '/salemanManagement',
            },
          ]
        : [
            {
              title: '四合院订单',
              key: 1,
              type: 'seeyardOrder',
              path: '/seeyardOrder',
            },
          ]
  },
  methods: {
    changeNav(item) {
      this.activeTableNav = item.type
      if (item.path != this.$route.path) {
        this.$router.push(item.path)
      }
    },
  },
  watch: {
    $route: {
      handler(to) {
        this.activeTableNav = to.meta.hash
      },
      immediate: true,
    },
  },
}
</script>

<style lang="less" scoped>
.main_nav {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  z-index: 99;
  height: 64px;
  padding: 0 32px;
  background-color: #fff;
  span {
    display: inline-block;
    width: 100px;
    height: 62px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 62px;
    text-align: center;
    cursor: pointer;
    &.activeNav {
      color: #ed7b2f;
      border-bottom: 2px solid #ed7b2f;
    }
  }
}
</style>
