import { ConfigProvider } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue/es'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Vue from 'vue'

import '@yige/base/bin/index.css'
import YTable from '@yige/ui/src/core/y-table/index.vue'

import App from './App.vue'
import './assets/css/common.css'
import router from './router'
import store from './store'
import request from './utils/server/request'

Vue.use(ConfigProvider)

Vue.use(ElementUI)
Vue.use(Antd)
Vue.use(ConfigProvider)
Vue.component('y-table', YTable)

Vue.config.productionTip = false
console.log(process.env)
Vue.prototype.$msgTip = function (msg, type = 'info', time = 1500, other = {}) {
  if (typeof msg == 'string') {
    ElementUI.Message({
      duration: time,
      message: msg,
      type: type,
      ...other,
    } as any)
  } else {
    ElementUI.Message({ ...msg, type: type, duration: time, ...other })
  }
}
request(Vue.prototype, router)
new Vue({
  router,
  store,
  render: (h) => h(App),
} as any).$mount('#app')
