<template>
  <div id="app">
    <div class="app-content">
      <!-- 头部 -->
      <BackHead v-if="!$route.meta.noHead" ref="backHeadRef"></BackHead>
      <MainNav v-if="!$route.meta.noHead" ref="mainNavRef"></MainNav>
      <!-- 导航 -->
      <!-- <div class="content-center"> -->
      <div
        class="content-center"
        :style="{
          marginTop: contentTop + 'px',
        }"
      >
        <a-config-provider :locale="zhCN">
          <router-view />
        </a-config-provider>
      </div>
    </div>
  </div>
</template>
<script setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { computed, onMounted, ref } from 'vue'

import BackHead from './components/backHead.vue'
import MainNav from './components/nav.vue'

const backHeadRef = ref()
const mainNavRef = ref()

const contentTop = computed(() => {
  let result = 0
  if (backHeadRef.value) {
    result += backHeadRef.value.offsetHeight
  }
  if (mainNavRef.value) {
    result += mainNavRef.value.offsetHeight
  }
  return result
})
// 设置成中文
moment.locale('zh-cn')

dayjs.locale('zh-cn')
// export default {
//   components: {
//     BackHead,
//     MainNav,
//   },
//   data() {
//     return {
//       zhCN,
//     }
//   },
// }
</script>
<style lang="less">
.app-content {
  // display: flex;
  // flex-direction: column;
  height: 100vh;
  background-color: rgba(240, 242, 245);
  // .content-center {
  //   flex: 1;
  //   height: 0;
  //   overflow-y: auto;
  // }
}
</style>
